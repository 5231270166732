<template>
    <div class="container-fluid full-section bg-white">
        <div class="container">
            <div class="faq-container">
                <div class="row">
                    <h2 class="title"><span>Часто задаваемые вопросы</span></h2>
                    <div class="w-100">
                        <div class="accordion" id="accordion" v-if="items">
                            <div class="accordion-item card" v-for="item in items" :key="item.id">
                                <h2 class="accordion-header card-header" :id="'heading' + item.id">
                                    <button class="accordion-button collapsed btn btn-block accordion-toggle" type="button" data-toggle="collapse" :data-target="'#collapse' + item.id" aria-expanded="true" :aria-controls="'collapse' + item.id">
                                        {{ item.title }}
                                    </button>
                                </h2>
                                <div :id="'collapse' + item.id" class="accordion-collapse collapse" :aria-labelledby="'heading' + item.id" data-parent="#accordion">
                                    <div class="accordion-body card-body" v-html="item.content"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {get} from '@/lib/axios';
import 'bootstrap/js/src/collapse';

export default {
    name: 'FAQ',
    data: () => ({
        items: [],
    }),
    methods: {
        async fetchData() {
            await get('faq').then(response => {
                this.items = response.data.data
            })
        }
    },
    mounted() {
        this.fetchData()
    },
    metaInfo() {
        return {
            title: `FAQ - ЛУКМАРКЕТ`,
        }
    },
}
</script>

<style scoped lang="scss">
.accordion {
    &-toggle {
        &:after {
            content: '';
            position: relative;
            border-style: solid;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 3px;
            transform: rotate(225deg);
            vertical-align: middle;
            color: #555;
        }
    }
}

.card {
    border-bottom: none;

    .btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: left;
        background-color: #fff;
        transition: none;
        box-shadow: none;
        white-space: pre-wrap;

        &.collapsed {

            &:after {
                transform: rotate(45deg);
            }
        }
    }

    &-header {
        background-color: #fff;
        padding: .4rem 1rem;

        &:hover, &:focus, &:active {
            background-color: #f5f5f5;

            .btn {
                background-color: #f5f5f5;
            }
        }
    }

}
.faq-container {
    margin-bottom: 30px;
}
</style>
